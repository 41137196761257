export enum GuestType {
    Host = 'Host',
    Guest = 'Guest'
}

export enum GuestRelation {
    // Host = 'Host', //! Doomed
    Husband = 'Husband',
    Wife = 'Wife',
    Partner = 'Partner',
    Daughter = 'Daughter',
    Son = 'Son',
    Mother = 'Mother',
    Father = 'Father',
    Brother = 'Brother',
    Sister = 'Sister',
    Friend = 'Friend',
    GrandFather = 'Grandfather',
    GrandMother = 'Grandmother',
    Aunt = 'Aunt',
    Uncle = 'Uncle',
    Cousin = 'Cousin',
    Family = 'Family',
    Colleague = 'Colleague'

}

export const GuestTypeOptions = Object.values(GuestType)
export const GuestRelationOptions = Object.values(GuestRelation)

export class GuestGeneral {
    public readonly type: GuestType

    constructor(
        public readonly email: string,
        public readonly first_name: string,
        public readonly guest_id: string,
        public readonly guest_name: string,
        public readonly id: string,
        public readonly last_name: string,
        public readonly phone: string,
        public readonly relation: GuestRelation,
        public readonly reservation_id: string,
        public readonly reservation_name: string,
        public readonly is_minor: boolean,
        private readonly _type?: GuestType
    ) {
        if (this._type === undefined) {
            this.type = this.relation === GuestType.Host as any
                ? GuestType.Host
                : GuestType.Guest
        } else {
            this.type = this._type
        }
    }

    // important for filters on guest table [Tabs]
    get isHost() {
        return this.type === GuestType.Host
    }
}

export function InstantiateGuestFromJson(json: any) {
    return new GuestGeneral(
        json.email,
        json.first_name,
        json.guest_id,
        json.guest_name,
        json.id,
        json.last_name,
        json.phone,
        json.relation,
        json.reservation_id,
        json.reservation_name,
        json.is_minor,
        json.type,
    );
}
