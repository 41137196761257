import { Box, Stack, Typography, useTheme } from '@mui/material';
import {
    Elements as StripeElements,
    PaymentElement as StripePaymentElement,
} from '@stripe/react-stripe-js';
import { BaseStripeElementsOptions, StripeError, StripePaymentElementOptions, loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { paymentEvent, useAnalyticsContext } from '../../analytics';
import { sendAndSwallowPurchaseEvent } from '../../analytics/sendAndSwallowPurchaseEvent';
import { brandConfig } from '../../config';
import { STRIPE_PUBLISHABLE_KEY } from '../../config-global';
import usePaymentMethods from '../../hooks/usePaymentMethods';
import useStripeCustomer from '../../hooks/useStripeCustomer';
import { ROIGeneral } from '../../models/ROIGeneral';
import { ReservationGeneral } from '../../models/ReservationGeneral';
import { PATH_APP } from '../../routes/paths';
import { StandardError } from '../../sections/error/StandardError';
import { StandardStack } from '../containers/StandardStack';
import { useSettingsContext } from '../settings';
import { PaymentLoadSpinner } from './PaymentLoadSpinner';
import PaymentMethodList from './PaymentMethodsList';
import { paymentAmountURLKey, paymentDescriptionURLKey, paymentOriginURLKey, paymentROISubTypeURLKey, paymentROITypeURLKey, paymentReservationURLKey } from './PaymentTypes';
import { StripePaymentActions } from './StripePaymentActions';

if (STRIPE_PUBLISHABLE_KEY === undefined) { throw new Error('No publishable key.') }
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
interface StripePaymentProps {
    amount: number;
    description?: string;
    return_url?: string;
    metadata?: { [key: string]: any };
    reservation?: ReservationGeneral;
    reservationOrderItem?: ROIGeneral;
    onClose?: VoidFunction;
    cancelButtonText?: string;
    onSuccessfulPayment?: () => Promise<{
        data: any;
    } | {
        error: unknown;
    }>;
    category: string;
}

export default function StripePayment(props: StripePaymentProps) {
    const {
        amount,
        return_url,
        description,
        metadata,
        reservation,
        reservationOrderItem,
        onClose,
        cancelButtonText = 'Cancel Payment',
        onSuccessfulPayment,
        category
    } = props;
    const theme = useTheme()
    const { themeMode } = useSettingsContext()
    // --------------- Customer -------------------------
    const {
        customer,
        loading: customerLoading,
        error: customerError
    } = useStripeCustomer();
    // --------------- Payment -------------------------
    const {
        defaultPaymentMethodId,
        paymentMethods,
        selectedPaymentMethodId,
        selectPaymentMethod,
        loading: methodsLoading,
        error: methodsError
    } = usePaymentMethods({ customer: customer! });
    const { tagPurchaseEvent } = useAnalyticsContext();
    const [showPaymentMethods, setShowPaymentMethods] = useState(false);
    const [paymentError, setPaymentError] = useState<StripeError | null>(null);
    const [newPaymentReady, setNewPaymentReady] = useState(false);

    useEffect(() => {
        if (paymentMethods && paymentMethods.length > 0) {
            setShowPaymentMethods(true);
        }
    }, [paymentMethods]);

    useEffect(() => {
        sendAndSwallowPurchaseEvent(category, amount, tagPurchaseEvent, undefined, reservation, reservationOrderItem, paymentEvent.BEGIN_CHECKOUT)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // --------------- Render Loading -------------------------
    if (customerLoading || methodsLoading) {
        return <PaymentLoadSpinner />;
    }
    // --------------- Render Error -------------------------
    if (customerError || !customer) {
        return (
            <StandardStack>
                <StandardError error={customerError} />
            </StandardStack>
        );
    }
    if (methodsError) {
        return (
            <StandardStack>
                <StandardError error={new Error('There was a problem with payment.')} />
            </StandardStack>
        );
    }

    const handleAddCardClick = () => {
        selectPaymentMethod('');
        return setShowPaymentMethods(false);
    }

    const handleReturnToSavedPaymentMethodsClick = () => {
        setPaymentError(null)
        selectPaymentMethod(defaultPaymentMethodId || paymentMethods[0].id);
        setShowPaymentMethods(true);
    }

    // build the params for the confirmation page and the post-confirmation redirect into the return url
    const paymentOriginURL = return_url || window.location.href;
    const confirmPageURL = window.location.origin + PATH_APP.payment.confirm;
    let combinedStripeReturnURL = confirmPageURL + '?' + paymentOriginURLKey + '=' + encodeURIComponent(paymentOriginURL);

    combinedStripeReturnURL += '&' + paymentAmountURLKey + '=' + encodeURIComponent(amount);

    if (description) {
        combinedStripeReturnURL += '&' + paymentDescriptionURLKey + '=' + encodeURIComponent(description);
    }

    if (reservation) {
        combinedStripeReturnURL += '&' + paymentReservationURLKey + '=' + encodeURIComponent(reservation.name);
    }
    else if (reservationOrderItem) {
        combinedStripeReturnURL += '&' + paymentReservationURLKey + '=' + encodeURIComponent(reservationOrderItem.reservation_name);
        combinedStripeReturnURL += '&' + paymentROITypeURLKey + '=' + encodeURIComponent(reservationOrderItem.type);
        combinedStripeReturnURL += '&' + paymentROISubTypeURLKey + '=' + encodeURIComponent(reservationOrderItem.sub_type);
    }

    // If there is an non-Stripe error (e.g. 'parsing circular json'), we show a generic message. The details are printed to the dev console.
    const nonStripeErrorDetected = paymentError !== null && (paymentError instanceof TypeError);

    const paymentElementOptions: StripePaymentElementOptions = {
        defaultValues: {
            billingDetails: {
                name: customer?.name || '',
                email: customer?.email || '',
                address: customer?.address || {}
            }
        },
        business: {
            name: brandConfig.name
        },
        paymentMethodOrder: ['card', 'us_bank_account'],
    }

    const appearance: BaseStripeElementsOptions['appearance'] = themeMode === 'dark' ? {
        theme: 'night',
        variables: {
            fontFamily: 'Sohne, system-ui, sans-serif',
            fontWeightNormal: '500',
            borderRadius: '8px',
            colorBackground: theme.palette.primary.main,
            colorPrimary: theme.palette.secondary.main,
            accessibleColorOnColorPrimary: '#1A1B25',
            colorText: theme.palette.text.primary,
            colorTextSecondary: theme.palette.text.primary,
            colorTextPlaceholder: theme.palette.text.disabled,
            tabIconColor: theme.palette.text.primary,
            logoColor: 'dark'
        },
        rules: {
            '.Input, .Block': {
                backgroundColor: 'transparent',
                border: '1.5px solid var(--colorPrimary)'
            }
        },
    } : {
        theme: 'stripe',
    };
    return (
        <StripeElements
            stripe={stripePromise}
            options={{
                mode: 'payment',
                appearance: appearance,
                amount: Math.floor(amount * 100),
                currency: 'usd',
                // payment_method_types: ['card', 'us_bank_account'],
                setup_future_usage: 'off_session'
            }}>
            <Stack spacing={1}>
                {paymentError && <StandardError error={paymentError} suppressOurTeamSuffix useGenericText={nonStripeErrorDetected} />}

                {showPaymentMethods && <Box>
                    <Typography variant="h6">Payment Method</Typography>
                    <PaymentMethodList
                        defaultPamentMethodId={defaultPaymentMethodId}
                        paymentMethods={paymentMethods || []}
                        selectedPaymentMethodId={selectedPaymentMethodId}
                        onPaymentMethodSelected={selectPaymentMethod}  // Pass the function here
                    />
                    <StripePaymentActions
                        category={category}
                        reservation={reservation}
                        reservationOrderItem={reservationOrderItem}
                        onSuccessfulPayment={onSuccessfulPayment}
                        cancelButtonText={cancelButtonText}
                        onClose={onClose}
                        amount={amount}
                        handleAddCardClick={handleAddCardClick}
                        handleReturnToSavedPaymentMethodsClick={handleReturnToSavedPaymentMethodsClick}
                        paymentMethods={paymentMethods}
                        selectedPaymentMethodId={selectedPaymentMethodId}
                        paymentError={paymentError}
                        setPaymentError={setPaymentError}
                        showPaymentMethods={showPaymentMethods}
                        return_url={combinedStripeReturnURL}
                        loading={methodsLoading}
                        disabled={!selectedPaymentMethodId}
                        description={description}
                        metadata={metadata}
                        customer={customer}
                    />
                </Box>}
                {!showPaymentMethods && <Box>
                    <StripePaymentElement
                        options={paymentElementOptions}
                        onLoaderStart={() => { setNewPaymentReady(false) }}
                        onReady={() => { setNewPaymentReady(true) }}
                        onLoadError={(error) => { setPaymentError(error.error) }}
                        onChange={() => { setPaymentError(null) }}
                    />
                    <StripePaymentActions
                        category={category}
                        reservation={reservation}
                        reservationOrderItem={reservationOrderItem}
                        onSuccessfulPayment={onSuccessfulPayment}
                        cancelButtonText={cancelButtonText}
                        onClose={onClose}
                        amount={amount}
                        handleAddCardClick={handleAddCardClick}
                        handleReturnToSavedPaymentMethodsClick={handleReturnToSavedPaymentMethodsClick}
                        paymentMethods={paymentMethods}
                        selectedPaymentMethodId={selectedPaymentMethodId}
                        paymentError={paymentError}
                        setPaymentError={setPaymentError}
                        showPaymentMethods={showPaymentMethods}
                        return_url={combinedStripeReturnURL}
                        loading={!newPaymentReady}
                        disabled={!newPaymentReady}
                        description={description}
                        metadata={metadata}
                        customer={customer}
                    />
                </Box>}
            </Stack>
        </StripeElements >
    );
};