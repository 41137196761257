import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Alert, IconButton, InputAdornment, Link, Stack, styled } from '@mui/material';
import { AuthErrorCodes } from 'firebase/auth';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
import { useAuthContext } from '../../auth/useAuthContext';
import FormProvider, { RHFTextField } from '../../components/hook-form';
import Iconify from '../../components/iconify';
import { CheckEnvironment, SupportedEnvironments } from '../../config-global';
import { PATH_AUTH } from '../../routes/paths';
import { safelyReadErrorMessage } from '../../utils/mrr/errorHandling';
import { clientVersion } from '../../utils/mrr/version';
import { EMAIL_REGEX } from '../../utils/mrr/uiConstants';

// ----------------------------------------------------------------------

const isDevelopment = CheckEnvironment(SupportedEnvironments.Dev)

// ----------------------------------------------------------------------


type FormValuesProps = {
  email: string;
  password: string;
  afterSubmit?: string;
};

export default function AuthLoginForm({ back }: { back: VoidFunction }) {
  const { login } = useAuthContext();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').matches(EMAIL_REGEX, 'Email must be a valid email address'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: ''
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues
    // TBD: It's a little obnoxious on this particular form (main login).
    // mode: 'onChange'
  });

  const {
    resetField,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await login(data.email, data.password);
    } catch (error) {
      console.error(error);

      resetField('password');
      resetField('afterSubmit');

      ProcessLoginError(error);

      setError('afterSubmit', {
        ...error,
        message: safelyReadErrorMessage(null, error),
      });
    }
  };


  return (
    <Stack>
      <FormProvider name='Developer Login Form' id='Developer Login Form' methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          {!!errors.afterSubmit
            && <Alert severity="error">
              {errors.afterSubmit.message}
            </Alert>
          }

          <StyleInput
            InputProps={
              {
                endAdornment:
                  <Iconify
                    color='inherit'
                    icon='eva:email-outline' ml={1} />
              }
            }

            name="email"
            label="Email address"
            type='email'
            autoFocus />
          <RHFTextField
            name="password"
            autoComplete='password'
            label="Password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Stack direction='row' justifyContent='space-between' my={2}>
          <Link
            onClick={back}
            variant="body2"
            color='primary'>
            Sign in with email
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitSuccessful || isSubmitting}
        >
          Login
        </LoadingButton>
      </FormProvider>

      <div style={{
        color: '#a6a6a6',
        fontSize: '10px',
        marginTop: '18px',
        visibility: isDevelopment ? 'visible' : 'hidden'
      }}
      >
        {`v${clientVersion}${isDevelopment ? ' (dev)' : ''}`}
      </div>
    </Stack>
  );
}

//-----------------

const StyleInput = styled(RHFTextField, {
})(({ theme }) => ({
  input: {
    '&:-webkit-autofill': {
      webkitBoxShadow: 'none !important',
      webkitTextFillColor: 'inherit',
      webkitBackgroundClip: 'text !important'
    }
  }
}))

const ProcessLoginError = (error: any) => {
  // NOTE: These come in two flavors. Both have the same 'header' and 'footer' (boilderplate prefix and suffix).
  //      Some have an additional verbose message between the two. We attempt to pull that text out cleanly.
  let candidateErrorCode = (error as any).message;
  let candidateErrorText = (error as any).message;

  const headerTag = 'Firebase: Error ';
  const footerTag = '(auth/';

  if (candidateErrorCode.indexOf(headerTag) === 0) {
    // strip header tag
    candidateErrorCode = candidateErrorCode.slice(headerTag.length);
    candidateErrorText = candidateErrorText.slice(headerTag.length);
  }

  const footerPos = candidateErrorCode.indexOf(footerTag);

  if (footerPos !== -1) {
    // footer start found

    // strip all but the footer (which is an error 'code' (string tag))
    candidateErrorCode = candidateErrorCode.slice(footerPos + 1, -2); // account for the ending ")."

    // strip the footer
    candidateErrorText = candidateErrorText.slice(0, footerPos);
  }

  const detailTextPrefix = 'Firebase: ';

  if (candidateErrorText.indexOf(detailTextPrefix) === 0) {
    candidateErrorText = candidateErrorText.slice(detailTextPrefix.length);
  }

  if (candidateErrorText.indexOf('auth/') !== -1) {
    console.warn('unknown error message format');
    // looks like we failed to strip the suffix; we will use the default text instead
    candidateErrorText = '';
  }

  switch (candidateErrorCode) {
    case AuthErrorCodes.INVALID_PASSWORD: error.message = 'Incorrect password.'; break; // "auth/wrong-password";
    case AuthErrorCodes.USER_DELETED: error.message = 'Account not found.'; break; // "auth/user-not-found";
    case AuthErrorCodes.USER_DISABLED: error.message = 'User is currently disabled. Please contact Guest Experience Team.'; break; // "auth/user-disabled";
    case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER: error.message = 'Too many failed attempts. Please try again later, or reset your password.'; break; // "auth/too-many-requests";

    // NOTE: These are here as failsafes, but we haven't seen them yet in the wild.
    case AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN: error.message = 'This action requires a recent login.'; break; // "auth/requires-recent-login";
    case AuthErrorCodes.EMAIL_EXISTS: error.message = 'This email is already in use.'; break; // "auth/email-already-in-use";
    case AuthErrorCodes.INVALID_EMAIL: error.message = 'This email is not valid.'; break; // "auth/invalid-email";
    case AuthErrorCodes.TIMEOUT: error.message = 'The operation timed out.'; break; // "auth/timeout";
    case AuthErrorCodes.NETWORK_REQUEST_FAILED: error.message = 'Network request failed.'; break; // "auth/network-request-failed";

    // NOTE: This one doesn't come up through login attempts. It's caught and handled by the OOB pages.
    case AuthErrorCodes.UNVERIFIED_EMAIL: error.message = 'This email has not been verified.'; break;// "auth/unverified-email";
    default: {
      console.warn('unsupported login error', (error as any).message);
      if (candidateErrorText.length >= 10) {
        // (It looks like) Firebase provided an instructional message with the error.
        error.message = candidateErrorText;
      }
      else {
        error.message = 'Unable to sign in.';
      }
      break;
    }
  }
}