import {
    Card,
    CardContent,
    CardHeader,
    Stack
} from "@mui/material";
import {
    blue,
    blueGrey,
    green,
    grey,
    orange,
    purple,
    red,
    yellow
} from "@mui/material/colors";
import { m } from 'framer-motion';
import { MouseEvent } from "react";
import { useFormContext } from "react-hook-form";
import Iconify from "../iconify";
import { ROISubType, ROIType } from "../../models/ROIGeneral";
import { CheckInRequest, ReadOnlyCheckInRequest } from "../../models/ReservationGeneral";

// ---------------------------
const ICON_SIZE = 55

const containerAnimation = {
    initial: {
        opacity: 0,
        scale: 1
    },
    whileInView: {
        opacity: 1,
        scale: .9
    },
    whileTap: {
        scale: 1
    },
    whileHover: {
        scale: 1
    },
};
const iconAnimation = {
    initial: {
        opacity: 0,
        scale: 0
    },
    whileInView: {
        opacity: 1,
        scale: 1,
    },
    whileTap: {
        scale: 1.2
    },
    whileHover: {
        scale: 1.2
    },
};
// ---------------------------

export function RHFCardPickerInput({
    option,
    icon,
    color,
    name,
    whenClicked
}: {
    option: string | null;
    icon: string;
    color: string;
    name: string;
    whenClicked?: VoidFunction
}) {
    const { setValue } = useFormContext()

    const onClick = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation()
        setValue(name, option)
        if (typeof whenClicked === 'function') {
            whenClicked()
        }
    };

    if (!option) {
        return null
    }

    return (
        <Card
            component={m.div}
            {...containerAnimation}
            id={option}
            sx={{
                bgcolor: color,
                height: 180,
            }}
            onClick={onClick}
        >
            <CardHeader
                title={option} />
            <CardContent>
                <Stack
                    component={m.div}
                    {...iconAnimation}
                    direction='row'
                    justifyContent='center'>
                    <Iconify
                        width={ICON_SIZE}
                        icon={icon} />
                </Stack>
            </CardContent>
        </Card>
    );
}

//--------------------
export function mapTypeToColor(type: string | null) {
    switch (type) {
        case CheckInRequest.HIGH_CHAIR:
            return green[100];
        case CheckInRequest.BOOSTER:
            return yellow[100];
        case CheckInRequest.PACK_AND_PLAY:
            return blue[100];
        case CheckInRequest.FUTON:
            return orange[100];
        default:
            return undefined;
    }
}
export function mapIconToType(roiType: ROIType | null) {
    switch (roiType) {
        case ROIType.CAR_RENTAL:
            return 'mdi:car-rental';
        case ROIType.CLEANING:
            return 'carbon:clean';
        case ROIType.INSURANCE:
            return 'streamline:insurance-hand';
        case ROIType.MISC:
            return 'codicon:symbol-misc';
        case ROIType.RENT:
            return 'ic:baseline-bedroom-parent';
        case ROIType.SPECIAL_REQUEST:
            return 'ooui:special-pages-rtl';
        default:
            return 'gg:add';
    }
}
export function mapIconToSubType(roiSubType: ROISubType | null) {
    switch (roiSubType) {
        case ROISubType.AIR_MATTRESS:
            return 'fa6-solid:mattress-pillow';
        case ROISubType.BIWEEKLY_TIDY:
            return 'carbon:clean';
        case ROISubType.BOOSTER:
            return 'icon-park-outline:booster-car-seat';
        case ROISubType.CAR_RENTAL:
            return 'clarity:car-line';
        case ROISubType.DAILY_HOUSEKEEPING:
            return 'arcticons:cleaner';
        case ROISubType.DAMAGE_WAIVER:
            return 'icon-park-outline:damage-map';
        case ROISubType.DEPARTURE_CLEAN:
            return 'eos-icons:cleanup';
        case ROISubType.DEPARTURE_LINENS:
            return 'icon-park-solid:towel';
        case ROISubType.DONATION:
            return 'mdi:donation';
        case ROISubType.FULL_CLEAN:
            return 'material-symbols:cleaning';
        case ROISubType.GENERAL_EXCISE:
            return 'tabler:receipt-tax';
        case ROISubType.GROCERY_DELIVERY:
            return 'emojione-monotone:delivery-truck';
        case ROISubType.HIGH_CHAIR:
            return 'icon-park-outline:chair';
        case ROISubType.LANAI_CLEAN:
            return 'mdi:patio-door-open';
        case ROISubType.LATE_CHECK_OUT:
            return 'mdi:latest';
        case ROISubType.LINENS:
            return 'game-icons:pillow';
        case ROISubType.LINENS_AND_TOWELS:
            return 'iconoir:pillow';
        case ROISubType.PACK_AND_PLAY:
            return 'guidance:stroller-parking';
        case ROISubType.PARKING_FEE:
            return 'maki:parking-garage';
        case ROISubType.PARTNER_FEE:
            return 'material-symbols:partner-exchange-outline-rounded';
        case ROISubType.PLATFORM_FEE:
            return 'carbon:data-base-alt';
        case ROISubType.PROCESSOR_FEE:
            return 'fluent-mdl2:processing';
        case ROISubType.RENT:
            return 'material-symbols-light:bedroom-parent-outline';
        case ROISubType.RESORT_FEE:
            return 'nimbus:money';
        case ROISubType.RICE_COOKER:
            return 'bx:bowl-rice';
        case ROISubType.ROLLAWAY_MATTRESS:
            return 'material-symbols:moving-beds-outline-rounded';
        case ROISubType.SERVICE_CHARGE:
            return 'uil:bill';
        case ROISubType.SPLIT_BED:
            return 'game-icons:bunk-beds';
        case ROISubType.TIDY:
            return 'carbon:clean';
        case ROISubType.TIMESHARE_OCCUPANCY:
            return 'subway:time';
        case ROISubType.TOWELS:
            return 'icon-park-outline:towel';
        case ROISubType.TRANSIENT_ACCOMMODATIONS:
            return 'mdi:airport-shuttle';
        case ROISubType.TRAVEL_INSURANCE:
            return 'streamline:insurance-hand';
        case ROISubType.FITNESS_CENTER:
        case ROISubType.FITNESS_CENTER_CODE:
            return 'ic:round-fitness-center';
        case ROISubType.UPCHARGE:
            return 'solar:bill-check-broken';
        case ROISubType.NOT_AVAILABLE:
        case ROISubType.OTHER:
        default:
            return 'ic:baseline-miscellaneous-services';
    }
}
export const ServiceTypeColors = [
    blue[50],
    blueGrey[50],
    green[50],
    grey[50],
    orange[50],
    purple[50],
    red[50],
    yellow[50],
];

export function mapRequestToIcon(request: CheckInRequest | ReadOnlyCheckInRequest | string) {
    switch (request) {
        case CheckInRequest.BOOSTER:
            return 'icon-park-outline:chair-one';
        case CheckInRequest.HIGH_CHAIR:
            return 'icon-park-outline:chair';
        case CheckInRequest.PACK_AND_PLAY:
            return 'icon-park-outline:backpack';
        case CheckInRequest.SPLIT_BED:
            return 'mdi:bunk-bed-outline';
        case CheckInRequest.FUTON:
            return 'la:couch';
        case ReadOnlyCheckInRequest.Cooler:
            return 'game-icons:cooler';
        case ReadOnlyCheckInRequest.Speaker:
            return 'mdi:speaker-bluetooth';
        case ReadOnlyCheckInRequest.Beach_Wagon:
            return 'game-icons:cartwheel';
        case ReadOnlyCheckInRequest.Spike_Ball:
            return 'game-icons:spikeball';
        case ReadOnlyCheckInRequest.Champagne:
            return 'game-icons:champagne-cork';
        case ReadOnlyCheckInRequest.Cookies:
            return 'fluent:cookies-24-regular';
        case ReadOnlyCheckInRequest.Frisbee:
            return 'game-icons:frisbee';
        case ReadOnlyCheckInRequest.Cornhole:
            return 'game-icons:throwing-ball';
        case ReadOnlyCheckInRequest.Oishii_Party_Mix:
            return 'dashicons:food';
        default:
            return 'codicon:symbol-misc';
    }
}