/* eslint-disable prefer-destructuring */
import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import { format } from 'date-fns';
import { httpsCallable } from 'firebase/functions';
import { GuestGeneral, InstantiateGuestFromJson } from '../../models/GuestGeneral';
// eslint-disable-next-line import/no-cycle
import { CLOUD_FUNCTIONS } from '../../auth/FirebaseContext';
import { InstantiateReservationFromJSON, ReservationGeneral } from '../../models/ReservationGeneral';
import { scopedRequestDateFormat } from '../../utils/formatTime';
import {
    // cloud_getAttachments,
    cloud_makeSalesforceRequest
} from '../../utils/mrr/cloudFunctions';
import { InstanciateGPISFromJSON } from "../../models/GPISGeneral";
// import { InstantiateAttachmentMetaFromJSON } from '../../models/AttachmentMeta';
import { CaseGeneral, InstantiateCaseFromJSON } from '../../models/CaseGeneral';
import { InstantiateListingFromJSON, ListingGeneral } from '../../models/ListingGeneral';
import { InstantiateROIFromJSON, ROIGeneral } from '../../models/ROIGeneral';
import { InstantiateRevenueFromJSON, RevenueGeneral } from '../../models/RevenueGeneral';
import { InstantiateTravelInsuranceROIFromJSON } from '../../models/TravelInsurance';
import {
    endpoint_deleteGuest,
    endpoint_getAdditionalROIs,
    endpoint_getCases,
    endpoint_getGuests,
    endpoint_getHybridReservations,
    endpoint_getListing,
    endpoint_getListings,
    endpoint_getPortalInfo,
    endpoint_getROIs,
    endpoint_getReservation,
    endpoint_getReservations,
    endpoint_getRevenue,
    endpoint_patchCheckinRequest,
    endpoint_patchCheckoutTime,
    endpoint_patchExtendStay,
    endpoint_patchFlightInformation,
    endpoint_postCase,
    endpoint_postCaseComment,
    endpoint_postGuest,
    endpoint_postLateCheckout,
    endpoint_postROIs,
    // endpoint_postTIConfirm,
    endpoint_postTIQuote,
    endpoint_putGuest
} from '../../utils/mrr/cloudEndpointNames';
import { brandConfig } from '../../config';

/*
RTK Query caching:

One method is based on 'subscriber count'.
A subscriber is a unique combo of call and params, so doing useGetListingsQuery('A')
and useGetListingsQuery('B') makes a subscriber count of two.
When subscriber count hits zero, basically meaning you've navigated away from the page,
the cache timer begins.
So, load page, nav away, wait the timeout, nav back, and you'll get a refetch. If you
nav back before the timout, you'll get cached data.
This can be set per-endpoint.

Another method is refetchOnMountOrArgChange, which can be set to bool (new call every mount),
or a number (new call on mount if X seconds have ellapsed). But that one doesn't (yet)
have a per-endpoint option. We have to pick a global value to share.

We're starting with a one-hour time, for both methods.

We use tags to invalidate data, forcing a refetch. This is done on every mutation. For
example, when you modify a booking (via PUT), that booking is invalidated (meaning removed
from the cache). Then, any components that subscribe to bookings AND provide the booking
tag, will refetch their data automatically.
*/

// -------------------------------------
// NOTE: This is used for both 'keep unused', after ref count hits zero, and 'force fetch new'
const cacheHoldTime = 60 * 60;

enum EndpointTags {
    Case = 'Case',
    Reservation = 'Reservation',
    Reservation_Guest = 'Reservation_Guest',
    Reservation_Order_Item = 'Reservation_Order_Item',
    Additonal_Reservation_Order_Item = 'Additonal_Reservation_Order_Item',
    Travel_Insurance = 'Travel_Insurance',
    Attachment = 'Attachment'
}

const tagArray = Object.values(EndpointTags);
// -------------------------------------

const unusedBaseQuery: BaseQueryFn = (arg, api, extraOptions) => {
    return { data: null }
}

function validateStandardResultOrThrow(result: any) {
    if (typeof result.data !== 'object'
        || result.data === null
        || (result.data as any).status !== 200) {
        throw new Error('Invalid result.');
    }
}

export const apiSlice = createApi({
    reducerPath: 'api', // this is the LOCAL path, in memory
    baseQuery: unusedBaseQuery,
    keepUnusedDataFor: cacheHoldTime, // RTKQ's default is one minute
    refetchOnMountOrArgChange: cacheHoldTime, // this should mean our cached data is never older than X seconds
    tagTypes: tagArray,
    endpoints(build) {
        return {
            getSingleListing: build.query({
                queryFn: async (args) => {
                    if (!args.listingId) {
                        throw new Error('Get listing query is missing an Id.');
                    }

                    const cloudFunction = httpsCallable(CLOUD_FUNCTIONS, cloud_makeSalesforceRequest + '?' + endpoint_getListing);

                    const data = {
                        method: 'GET',
                        path: '/api/guest/listing/' + args.listingId,
                        params: {},
                        body: ''
                    };

                    const cloudResult = await cloudFunction(data)
                        .then(async (result) => {
                            validateStandardResultOrThrow(result);

                            if (!(result.data as any).listing) {
                                throw new Error('Failed to get listing.');
                            }

                            const listingModel: ListingGeneral = InstantiateListingFromJSON((result.data as any).listing);

                            return { data: listingModel };
                        })
                        .catch((error) => {
                            // this format is required by RTKQ
                            return { error }
                        });

                    return cloudResult;
                }
            }),
            getListings: build.query({
                queryFn: async (args) => {
                    const cloudFunction = httpsCallable(CLOUD_FUNCTIONS, cloud_makeSalesforceRequest + '?' + endpoint_getListings);

                    const data = {
                        method: 'GET',
                        path: '/api/guest/listing/list',
                        params: {},
                        body: ''
                    };

                    const cloudResult = await cloudFunction(data)
                        .then(async (result) => {
                            validateStandardResultOrThrow(result);

                            if (!Array.isArray((result.data as any).listings)) {
                                throw new Error('Failed to get listings.');
                            }

                            const { listings } = result.data as any;

                            const listingModels: ListingGeneral[] = listings.map((listingJSON: any) => {
                                const modelInstance = InstantiateListingFromJSON(listingJSON);
                                return modelInstance;
                            });

                            return { data: listingModels };
                        })
                        .catch((error) => {
                            // this format is required by RTKQ
                            return { error }
                        });

                    return cloudResult;
                }
            }),
            getCases: build.query({
                queryFn: async (args) => {
                    const cloudFunction = httpsCallable(CLOUD_FUNCTIONS, cloud_makeSalesforceRequest + '?' + endpoint_getCases);

                    // if there is no reservation, this fetches all of the user's cases
                    const data = {
                        method: 'GET',
                        path: '/api/guest/case/list',
                        params: args.reservationName ? { reservation_name: args.reservationName } : {},
                        body: ''
                    };

                    const cloudResult = await cloudFunction(data)
                        .then(async (result) => {
                            validateStandardResultOrThrow(result);

                            if (!Array.isArray((result.data as any).cases)) {
                                throw new Error('Failed to get cases.');
                            }

                            if (!Array.isArray((result.data as any).cases)) {
                                throw new Error('Failed to get cases.')
                            }
                            const { cases } = result.data as any;

                            const caseModels: CaseGeneral[] = cases.map((caseJSON: any) => {
                                const modelInstance = InstantiateCaseFromJSON(caseJSON);
                                return modelInstance;
                            });

                            return { data: caseModels };
                        })
                        .catch((error) => {
                            return { error }
                        });

                    return cloudResult;
                },
                // These can be invalidated to force refresh on update.
                providesTags: (result, error, args) => [{ type: EndpointTags.Case, id: args.reservationName }]
            }),
            getHybridReservations: build.query({
                queryFn: async (args) => {
                    const cloudFunction = httpsCallable(CLOUD_FUNCTIONS, cloud_makeSalesforceRequest + '?' + endpoint_getHybridReservations);

                    if (!args.reservationName) {
                        throw new Error('Get hybrid children missing reservation name.');
                    }

                    const data = {
                        method: 'GET',
                        path: '/api/guest/reservation/' + args.reservationName + '/hybrid_reservations',
                        params: {},
                        body: ''
                    };

                    const cloudResult = await cloudFunction(data)
                        .then(async (result) => {
                            validateStandardResultOrThrow(result);

                            if (!Array.isArray((result.data as any).reservations)) {
                                throw new Error('Failed to get hybrid reservations.');
                            }

                            const { reservations } = result.data as any;

                            const reservationModels: ReservationGeneral[] = reservations.map((reservationJSON: any) => {
                                const modelInstance = InstantiateReservationFromJSON(reservationJSON);
                                return modelInstance;
                            });

                            return { data: reservationModels };
                        })
                        .catch((error) => {
                            // this format is required by RTKQ
                            return { error }
                        });

                    return cloudResult;
                },
                // These can be invalidated to force refresh on update.
                providesTags: (result, error, args) => [EndpointTags.Reservation]
            }),
            getReservations: build.query({
                queryFn: async (args) => {
                    const cloudFunction = httpsCallable(CLOUD_FUNCTIONS, cloud_makeSalesforceRequest + '?' + endpoint_getReservations);
                    const params: Record<string, string> = {};
                    if (args.listingId) {
                        params.listing_id = args.listingId;
                    }
                    if (args.startDate) {
                        params.start_date = format(args.startDate, scopedRequestDateFormat);
                    }
                    if (args.endDate) {
                        params.end_date = format(args.endDate, scopedRequestDateFormat);
                    }

                    const data = {
                        method: 'GET',
                        path: '/api/guest/reservation/list',
                        params: params,
                        body: ''
                    };

                    const cloudResult = await cloudFunction(data)
                        .then(async (result) => {
                            validateStandardResultOrThrow(result);
                            if (!Array.isArray((result.data as any).reservations)) {
                                throw new Error('Failed to get reservations.');
                            }
                            const { reservations } = result.data as any;
                            const reservationModels: ReservationGeneral[] = reservations.map((reservationJSON: any) => {
                                const modelInstance = InstantiateReservationFromJSON(reservationJSON);
                                return modelInstance;
                            });

                            return { data: reservationModels };
                        })
                        .catch((error) => {
                            // this format is required by RTKQ
                            return { error }
                        });
                    return cloudResult;
                },
                // These can be invalidated to force refresh on update.
                providesTags: (result, error, args) => [EndpointTags.Reservation]
            }),
            getSingleReservation: build.query({
                queryFn: async (args) => {
                    if (!args.reservationName) {
                        throw new Error('Invalid attempt to get booking.');
                    }

                    const cloudFunction = httpsCallable(CLOUD_FUNCTIONS, cloud_makeSalesforceRequest + '?' + endpoint_getReservation);

                    const data = {
                        method: 'GET',
                        path: '/api/guest/reservation/' + args.reservationName,
                        params: {},
                        body: ''
                    };

                    const cloudResult = await cloudFunction(data)
                        .then((result) => {
                            validateStandardResultOrThrow(result);

                            if (!(result.data as any).reservation) {
                                throw new Error('Failed to get booking.');
                            }

                            if (Array.isArray((result.data as any).reservation_order_items)) {
                                const roiArray = (result.data as any).reservation_order_items;
                                if (roiArray.length > 0 && roiArray[0].insurance_policy_id) {
                                    const policyID = roiArray[0].insurance_policy_id;
                                    (result.data as any).reservation.insurance_policy_id = policyID;
                                }
                                // else no TI ROI policy ID, or policy missing/empty
                            }
                            // else no rois included w/ this reservation

                            const reservationModel: ReservationGeneral = InstantiateReservationFromJSON((result.data as any).reservation);

                            // this format is required by RTKQ
                            return { data: reservationModel };
                        })
                        .catch((error) => {
                            // this format is required by RTKQ
                            return { error };
                        });

                    return cloudResult;
                },
                // These can be invalidated to force refresh on update.
                providesTags: (result, error, args) => [
                    { type: EndpointTags.Reservation, id: args.reservationName }
                ]
            }),
            getReservationGuests: build.query({
                queryFn: async (args) => {
                    if (!args.reservationName) {
                        throw new Error('Invalid attempt to get guest for reservation.');
                    }

                    const cloudFunction = httpsCallable(CLOUD_FUNCTIONS, cloud_makeSalesforceRequest + '?' + endpoint_getGuests);

                    const data = {
                        method: 'GET',
                        path: '/api/guest/reservation_guest',
                        params: { reservation_name: args.reservationName },
                        body: ''
                    };

                    const cloudResult = await cloudFunction(data)
                        .then((result) => {
                            validateStandardResultOrThrow(result);

                            if (!(result.data as any).reservation_guests) {
                                throw new Error(`Failed to get guest for ${args.reservationName}.`);
                            }

                            const guestModels: GuestGeneral[] = (result.data as any).reservation_guests.map((guestJson: any) => InstantiateGuestFromJson(guestJson));

                            // this format is required by RTKQ
                            return { data: guestModels };
                        })
                        .catch((error) => {
                            // this format is required by RTKQ
                            return { error };
                        });

                    return cloudResult;
                },

                // These can be invalidated to force refresh on update.
                providesTags: (result, error, args) => [{ type: EndpointTags.Reservation_Guest, id: args.reservationName }]
            }),
            patchFlightInformation: build.mutation({
                queryFn: async (args) => {
                    if (!args.reservationName) {
                        throw new Error('Flight Information missing reservation id.');
                    }

                    const body = { ...args };

                    // this is not part of the actual request body, but we still want it on args
                    delete body.reservationName;

                    const cloudFunction = httpsCallable(CLOUD_FUNCTIONS, cloud_makeSalesforceRequest + '?' + endpoint_patchFlightInformation);

                    const data = {
                        method: 'PATCH',
                        path: '/api/guest/reservation/' + args.reservationName + '/flight_information',
                        params: {},
                        body: body
                    };

                    const cloudResult = await cloudFunction(data)
                        .then((result) => {
                            validateStandardResultOrThrow(result)

                            return { data: true };
                        })
                        .catch((error) => {
                            // this format is required by RTKQ
                            return { error };
                        });

                    return cloudResult;
                },
                // This endpoint causes items with these tags to be refetched.
                invalidatesTags: (result, error, args) => [
                    { type: EndpointTags.Reservation, id: args.reservationName }
                ]
            }),
            patchCheckinRequest: build.mutation({
                queryFn: async (args) => {
                    if (!args.reservationName) {
                        throw new Error('Check-in request missing reservation id.');
                    }

                    const body = { ...args };

                    // this is not part of the actual request body, but we still want it on args
                    delete body.reservationName;

                    const cloudFunction = httpsCallable(CLOUD_FUNCTIONS, cloud_makeSalesforceRequest + '?' + endpoint_patchCheckinRequest);

                    const data = {
                        method: 'PATCH',
                        path: '/api/guest/reservation/' + args.reservationName + '/checkin_requests',
                        params: {},
                        body: body
                    };

                    const cloudResult = await cloudFunction(data)
                        .then((result) => {
                            validateStandardResultOrThrow(result)

                            return { data: true };
                        })
                        .catch((error) => {
                            // this format is required by RTKQ
                            return { error };
                        });

                    return cloudResult;
                },
                // This endpoint causes items with these tags to be refetched.
                invalidatesTags: (result, error, args) => [
                    { type: EndpointTags.Reservation, id: args.reservationName },
                ]
            }),
            patchCheckoutTime: build.mutation({
                queryFn: async (args) => {
                    if (!args.reservationName) {
                        throw new Error('Unable to extend check-out time.');
                    }

                    const body = { ...args };

                    // this is not part of the actual request body, but we still want it on args
                    delete body.reservationName;

                    const cloudFunction = httpsCallable(CLOUD_FUNCTIONS, cloud_makeSalesforceRequest + '?' + endpoint_patchCheckoutTime);

                    const data = {
                        method: 'PATCH',
                        path: '/api/guest/reservation/' + args.reservationName + '/check_in_out_time',
                        params: {},
                        body: body
                    };

                    const cloudResult = await cloudFunction(data)
                        .then((result) => {
                            validateStandardResultOrThrow(result)

                            return { data: true };
                        })
                        .catch((error) => {
                            // this format is required by RTKQ
                            return { error };
                        });

                    return cloudResult;
                },
                // This endpoint causes items with these tags to be refetched.
                invalidatesTags: (result, error, args) => [
                    { type: EndpointTags.Reservation, id: args.reservationName }
                ]
            }),
            postReservationGuest: build.mutation({
                queryFn: async (args) => {
                    if (!args.reservationName) {
                        throw new Error('Unable to add guest without an Id.');
                    }

                    const body = { ...args };

                    // this is not part of the actual request body, but we still want it on args
                    delete body.reservationName;

                    // Filter off email and phone from minors
                    if (body.is_minor) {
                        delete body.email
                        delete body.phone
                    }

                    const cloudFunction = httpsCallable(CLOUD_FUNCTIONS, cloud_makeSalesforceRequest + '?' + endpoint_postGuest);

                    const data = {
                        method: 'POST',
                        path: '/api/guest/reservation_guest',
                        params: { reservation_name: args.reservationName },
                        body: body
                    };

                    const cloudResult = await cloudFunction(data)
                        .then((result) => {
                            validateStandardResultOrThrow(result)

                            return { data: true };
                        })
                        .catch((error) => {
                            // this format is required by RTKQ
                            return { error };
                        });

                    return cloudResult;
                },
                // This endpoint causes items with these tags to be refetched.
                invalidatesTags: (result, error, args) => [
                    { type: EndpointTags.Reservation_Guest, id: args.reservationName },
                ]
            }),
            putReservationGuest: build.mutation({
                queryFn: async (args) => {
                    if (!args.guestId) {
                        throw new Error('Unable to modify guest without an Id.');
                    }

                    //NOTE: This arg is purely for the refetch via invalidate tags.
                    if (!args.reservationName) {
                        throw new Error('Unable to modify guest without a reservation.');
                    }

                    const body = { ...args };

                    // these are not part of the actual request body, but we still want them on args
                    delete body.reservationName;
                    delete body.guestId;

                    const cloudFunction = httpsCallable(CLOUD_FUNCTIONS, cloud_makeSalesforceRequest + '?' + endpoint_putGuest);

                    const data = {
                        method: 'PUT',
                        path: '/api/guest/reservation_guest/' + args.guestId,
                        params: {},
                        body: body
                    };

                    const cloudResult = await cloudFunction(data)
                        .then((result) => {
                            validateStandardResultOrThrow(result)

                            return { data: true };
                        })
                        .catch((error) => {
                            // this format is required by RTKQ
                            return { error };
                        });

                    return cloudResult;
                },
                // This endpoint causes items with these tags to be refetched.
                invalidatesTags: (result, error, args) => [
                    { type: EndpointTags.Reservation_Guest, id: args.reservationName },
                ]
            }),
            deleteReservationGuest: build.mutation({
                queryFn: async (args) => {
                    if (!args.guestId) {
                        throw new Error('Unable to delete guest without an Id.');
                    }

                    //NOTE: This arg is purely for the refetch via invalidate tags.
                    if (!args.reservationName) {
                        throw new Error('Unable to delete guest without a reservation.');
                    }

                    const body = { ...args };

                    // these are not part of the actual request body, but we still want them on args
                    delete body.reservationName;
                    delete body.guestId;

                    const cloudFunction = httpsCallable(CLOUD_FUNCTIONS, cloud_makeSalesforceRequest + '?' + endpoint_deleteGuest);

                    const data = {
                        method: 'DELETE',
                        path: '/api/guest/reservation_guest/' + args.guestId,
                        params: {},
                        body: body
                    };

                    const cloudResult = await cloudFunction(data)
                        .then((result) => {
                            validateStandardResultOrThrow(result)

                            return { data: true };
                        })
                        .catch((error) => {
                            // this format is required by RTKQ
                            return { error }
                        });

                    return cloudResult;
                },
                // This endpoint causes items with these tags to be refetched.
                invalidatesTags: (result, error, args) => [
                    { type: EndpointTags.Reservation_Guest, id: args.reservationName },
                ]
            }),
            getReservationOrderItems: build.query({
                queryFn: async (args) => {
                    if (!args.reservationName) {
                        throw new Error('Unable to get reservation order items for unknown reservation.')
                    }

                    const cloudFunction = httpsCallable(CLOUD_FUNCTIONS, cloud_makeSalesforceRequest + '?' + endpoint_getROIs);

                    const data = {
                        method: 'GET',
                        path: '/api/guest/reservation_order_item',
                        params: { reservation_name: args.reservationName },
                        body: ''
                    };

                    const cloudResult = await cloudFunction(data)
                        .then((result) => {
                            validateStandardResultOrThrow(result)
                            if (!(result.data as any)?.reservation_order_items) {
                                throw new Error(`Failed to get additional reservation order items for ${args.reservationName}.`)
                            }
                            // const ROIs = result.data.reservation_order_items.map((roi) => {
                            const ROIs: ROIGeneral[] = (result.data as any).reservation_order_items.map((roiJSON: any) => {
                                return InstantiateROIFromJSON(roiJSON)
                            })
                            return { data: ROIs }
                        })
                        .catch((error) => {
                            return { error }
                        })
                    return cloudResult
                },
                // This endpoint causes items with these tags to be refetched.
                providesTags: (result, error, args) => [
                    { type: EndpointTags.Reservation_Order_Item, id: args.reservationName },
                ]
            }),
            getPortalInfo: build.query({
                queryFn: async (args) => {
                    if (!args.reservationName && !args.listingId) {
                        throw new Error('Unable to get portal info; must provide a reservation or listing.')
                    }

                    const params: Record<string, string> = {};

                    if (args.listingId) {
                        params.listing_id = args.listingId;
                    }

                    if (args.reservationName) {
                        params.reservation_name = args.reservationName;
                    }

                    const cloudFunction = httpsCallable(CLOUD_FUNCTIONS, cloud_makeSalesforceRequest + '?' + endpoint_getPortalInfo);

                    const data = {
                        method: 'GET',
                        path: '/api/guest/guest_portal_info',
                        params: params,
                        body: ''
                    };

                    const cloudResult = await cloudFunction(data)
                        .then((result) => {
                            validateStandardResultOrThrow(result)
                            if (!(result.data as any)?.guest_portal_info) {
                                throw new Error(`Failed to get ${args.reservationName ? 'reservation' : 'listing'} info.`)
                            }
                            const model = InstanciateGPISFromJSON((result.data as any).guest_portal_info)
                            return { data: model }
                        })
                        .catch((error) => {
                            return { error }
                        })
                    return cloudResult
                },
            }),
            getReservationRevenue: build.query({
                queryFn: async (args) => {
                    if (!args.reservationName) {
                        throw new Error('Unable to get reservation revenue for unknown reservation.')
                    }

                    const cloudFunction = httpsCallable(CLOUD_FUNCTIONS, cloud_makeSalesforceRequest + '?' + endpoint_getRevenue);

                    const data = {
                        method: 'GET',
                        path: '/api/guest/reservation_revenue',
                        params: { reservation_name: args.reservationName },
                        body: ''
                    };

                    const cloudResult = await cloudFunction(data)
                        .then((result) => {
                            validateStandardResultOrThrow(result)
                            if (!(result.data as any)?.reservation_revenues) {
                                throw new Error(`Failed to get additional reservation order items for ${args.reservationName}.`)
                            }
                            const revenueItems: RevenueGeneral[] = (result.data as any).reservation_revenues.map((revenueJSON: any) => {
                                revenueJSON.reservation_name = args.reservationName
                                return InstantiateRevenueFromJSON(revenueJSON)
                            })
                            return { data: revenueItems }
                        })
                        .catch((error) => {
                            return { error }
                        })
                    return cloudResult
                },
            }),
            getAdditionalServices: build.query({
                queryFn: async (args) => {
                    if (!args.reservationName) {
                        throw new Error('Unable to get additional reservation order items for unknown reservation.')
                    }

                    const cloudFunction = httpsCallable(CLOUD_FUNCTIONS, cloud_makeSalesforceRequest + '?' + endpoint_getAdditionalROIs);

                    const data = {
                        method: 'GET',
                        path: '/api/guest/reservation_order_item/additional_services',
                        params: { reservation_name: args.reservationName },
                        body: ''
                    };

                    const cloudResult = await cloudFunction(data)
                        .then((result) => {
                            validateStandardResultOrThrow(result)
                            if (!(result.data as any)?.reservation_order_items) {
                                throw new Error(`Failed to get additional reservation order items for ${args.reservationName}.`)
                            }
                            const ROIs: ROIGeneral[] = (result.data as any).reservation_order_items.map((roiJSON: any) => {
                                roiJSON.reservation_name = args.reservationName
                                return InstantiateROIFromJSON(roiJSON)
                            })
                            return { data: ROIs }
                        })
                        .catch((error) => {
                            return { error }
                        })
                    return cloudResult
                },
                // This endpoint causes items with these tags to be refetched.
                providesTags: (result, error, args) => [
                    { type: EndpointTags.Additonal_Reservation_Order_Item, id: args.reservationName },
                ]
            }),
            postReservationOrderItems: build.mutation({
                queryFn: async (args) => {
                    if (!args.reservationName) {
                        throw new Error('Unable to post additional reservation order items for unknown reservation.')
                    }

                    const body = { ...args };

                    // this is not part of the actual request body, but we still want it on args
                    delete body.reservationName;

                    const cloudFunction = httpsCallable(CLOUD_FUNCTIONS, cloud_makeSalesforceRequest + '?' + endpoint_postROIs);

                    const data = {
                        method: 'POST',
                        path: '/api/guest/reservation_order_item/additional_service',
                        params: { reservation_name: args.reservationName },
                        body: body
                    };

                    const cloudResult = await cloudFunction(data)
                        .then((result) => {
                            validateStandardResultOrThrow(result)
                            if (!(result.data as any)?.reservation_order_item) {
                                throw new Error(`Failed to add additional items to ${args.reservationName}.`)
                            }
                            return { data: InstantiateROIFromJSON((result.data as any).reservation_order_item) }
                        })
                        .catch((error) => {
                            return { error }
                        })
                    return cloudResult
                },
                // This endpoint causes items with these tags to be refetched.
                invalidatesTags: (result, error, args) => [
                    { type: EndpointTags.Additonal_Reservation_Order_Item, id: args.reservationName },
                    { type: EndpointTags.Reservation_Order_Item, id: args.reservationName },
                ]
            }),
            postLateCheckout: build.mutation({
                queryFn: async (args) => {
                    if (!args.reservationName || !args.checkOutTime) {
                        throw new Error('Unable to add late check-out.')
                    }

                    const body = { ...args };

                    // these are not part of the actual request body, but we still want them on args
                    delete body.reservationName;
                    delete body.checkOutTime;

                    const cloudFunction = httpsCallable(CLOUD_FUNCTIONS, cloud_makeSalesforceRequest + '?' + endpoint_postLateCheckout);

                    const data = {
                        method: 'POST',
                        path: '/api/guest/reservation_order_item/late_check_out',
                        params: { reservation_name: args.reservationName, check_out_time: args.checkOutTime },
                        body: body
                    };

                    const cloudResult = await cloudFunction(data)
                        .then((result) => {
                            validateStandardResultOrThrow(result)
                            if (!(result.data as any)?.reservation_order_item) {
                                throw new Error(`Failed to add late check-out to ${args.reservationName}.`)
                            }
                            return { data: InstantiateROIFromJSON((result.data as any).reservation_order_item) }
                        })
                        .catch((error) => {
                            return { error }
                        })
                    return cloudResult
                },
                // This endpoint causes items with these tags to be refetched.
                invalidatesTags: (result, error, args) => [
                    { type: EndpointTags.Reservation, id: args.reservationName },
                    { type: EndpointTags.Reservation_Order_Item, id: args.reservationName },
                ]
            }),
            patchExtendedStay: build.mutation({
                queryFn: async (args) => {
                    if (!args.reservationName) {
                        throw new Error('Unable to extend reservation.')
                    }

                    if (!args.days) {
                        throw new Error('Unable to extend reservation to undefined date.')
                    }

                    const body = { ...args };

                    // this is not part of the actual request body, but we still want it on args
                    delete body.reservationName;
                    delete body.days;

                    const cloudFunction = httpsCallable(CLOUD_FUNCTIONS, cloud_makeSalesforceRequest + '?' + endpoint_patchExtendStay);

                    const data = {
                        method: 'PATCH',
                        path: '/api/guest/reservation/' + args.reservationName + '/extend',
                        params: { days: args.days },
                        body: body
                    };

                    const cloudResult = await cloudFunction(data)
                        .then((result) => {
                            validateStandardResultOrThrow(result)
                            const { data: responseData } = result as any

                            if (responseData?.status !== 200 || !responseData.reservation) {
                                throw new Error(`Failed to extend ${args.reservationName}.`)
                            }
                            return { data: responseData.reservation }
                        })
                        .catch((error) => {
                            return { error }
                        })
                    return cloudResult
                },
                // This endpoint causes items with these tags to be refetched.
                invalidatesTags: (result, error, args) => [
                    { type: EndpointTags.Reservation, id: args.reservationName }
                ]
            }),
            postCase: build.mutation({
                queryFn: async (args) => {
                    if (!args.reservationName) {
                        throw new Error('Unable to post case for unknown reservation.')
                    }
                    if (!args.description) {
                        throw new Error('Missing required case description.')
                    }
                    if (!args.subject) {
                        throw new Error('Missing required case subject.')
                    }
                    if (!args.type) {
                        throw new Error('Missing required case type.')
                    }

                    if (!args.businessUnit) {
                        args.businessUnit = brandConfig.brandCode;
                    }

                    const body = { ...args };

                    // this is not part of the actual request body, but we still want it on args
                    delete body.reservationName;

                    const cloudFunction = httpsCallable(CLOUD_FUNCTIONS, cloud_makeSalesforceRequest + '?' + endpoint_postCase);

                    const data = {
                        method: 'POST',
                        path: '/api/guest/case',
                        params: { reservation_name: args.reservationName },
                        body: body
                    };

                    const cloudResult = await cloudFunction(data)
                        .then((result) => {
                            validateStandardResultOrThrow(result)

                            //NOTE: This model uses "sf_case" because "case" is a reserved keyword.

                            if (!((result.data as any)?.sf_case)) {
                                throw new Error(`Failed to post case for ${args.reservationName}.`)
                            }

                            return { data: (result.data as any)?.sf_case }
                        })
                        .catch((error) => {
                            return { error }
                        })
                    return cloudResult
                },
                invalidatesTags: (result, error, args) => [EndpointTags.Case]
            }),
            postTravelInsuranceQuote: build.query({//[[POST AS QUERY]]
                queryFn: async (args) => {
                    if (!args.reservationName) {
                        throw new Error('Unable to retrieve travel insurance quote for unknown reservation.')
                    }

                    const cloudFunction = httpsCallable(CLOUD_FUNCTIONS, cloud_makeSalesforceRequest + '?' + endpoint_postTIQuote);

                    const data = {
                        method: 'POST',
                        path: '/api/guest/reservation_order_item/travel_insurance_quote',
                        params: { reservation_name: args.reservationName },
                        body: ''
                    };

                    const cloudResult = await cloudFunction(data)
                        .then((result) => {
                            validateStandardResultOrThrow(result)
                            if (!((result.data as any)?.reservation_order_item)) {
                                throw new Error(`Failed to retrieve travel insurance quote for ${args.reservationName}.`)
                            }

                            return { data: InstantiateTravelInsuranceROIFromJSON((result.data as any).reservation_order_item) }
                        })
                        .catch((error) => {
                            return { error }
                        })
                    return cloudResult
                },
                providesTags: (result, error, args) => [
                    { type: EndpointTags.Travel_Insurance, id: args.reservationName },
                ]
            }),
            postCaseComment: build.mutation({
                queryFn: async (args) => {
                    if (!args.id || !args.body) {
                        throw new Error('Comment form incomplete.');
                    }

                    const body = { ...args };

                    // this is not part of the actual request body, but we still want it on args
                    delete body.id;

                    const cloudFunction = httpsCallable(CLOUD_FUNCTIONS, cloud_makeSalesforceRequest + '?' + endpoint_postCaseComment);

                    const data = {
                        method: 'POST',
                        path: '/api/guest/case/comment',
                        params: { id: args.id },
                        body: body
                    };

                    const cloudResult = await cloudFunction(data)
                        .then((result) => {
                            validateStandardResultOrThrow(result)

                            if (!(result.data as any).case_comment) {
                                throw new Error('Failed to add comment.');
                            }

                            // this format is required by RTKQ
                            return { data: true };
                        })
                        .catch((error) => {
                            // this format is required by RTKQ
                            return { error: error };
                        });

                    return cloudResult;
                },
                // This endpoint causes items with these tags to be refetched.
                invalidatesTags: (result, error, args) => [EndpointTags.Case]
            }),
            getAttachments: build.query({
                // keepUnusedDataFor: ?, // this will override the app-level setting
                queryFn: async (args) => {
                    return { data: [] };
                    /* Attachments are disabled by design.
                    if (!args.objectId) {
                        throw new Error('Invalid attempt to get attachments.');
                    }

                    const cloudFunction = httpsCallable(CLOUD_FUNCTIONS, cloud_getAttachments);

                    const data = {
                        object_id: args.objectId
                    };

                    const cloudResult = await cloudFunction(data)
                        .then((result) => {
                            validateStandardResultOrThrow(result);
                            if (!Array.isArray((result.data as any).attachments)) {
                                throw new Error('Failed to get attachments.'); //! This error is public to the user.
                            }
                            const models = (result.data as any).attachments.map((json: any) => InstantiateAttachmentMetaFromJSON(json))
                            return { data: models };
                        })
                        .catch((error) => {
                            // this format is required by RTKQ
                            return { error: error }
                        });

                    return cloudResult;
                    */
                }
                // Attachments are disabled by design.
                // providesTags: (result, error, args) => [
                //     { type: EndpointTags.Attachment, id: args.? },
                // ]
            }),
        }
    }
})

export const {
    useDeleteReservationGuestMutation,
    useGetAdditionalServicesQuery,
    useGetCasesQuery,
    useGetHybridReservationsQuery,
    useGetListingsQuery,
    useGetPortalInfoQuery,
    useGetReservationOrderItemsQuery,
    useGetReservationRevenueQuery,
    useGetReservationGuestsQuery,
    useGetReservationsQuery,
    useGetSingleListingQuery,
    useGetSingleReservationQuery,
    usePatchFlightInformationMutation,
    usePostReservationOrderItemsMutation,
    usePostReservationGuestMutation,
    usePutReservationGuestMutation,
    usePatchExtendedStayMutation,
    usePatchCheckinRequestMutation,
    usePatchCheckoutTimeMutation,
    usePostCaseMutation,
    usePostTravelInsuranceQuoteQuery,
    usePostCaseCommentMutation,
    useGetAttachmentsQuery,
    usePostLateCheckoutMutation
} = apiSlice