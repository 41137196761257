import { User } from 'firebase/auth';
import { Roles, UserStatus, roleNames } from '../utils/mrr/userConstants';
import countryConfig, { DefaultCountry, checkCodeIsLikeUS } from '../utils/mrr/phone/countryConfig';

// IMPORTANT: This is a replacement for IUserAccountGeneral. It might be usable everywhere
//           that type is used, without changes, casting, "?" or "!" TypeScript cheats, etc...
export class UserAccountGeneral {
	// Any last-login before this time has never logged in (roughly)
	static readonly EARLIEST_LOGIN_TIME = 1695168000000; // Sep 20, 2023 12:00:00 AM UTC (just before public launch)

	private _firebaseUser: null | User = null;
	public readonly displayName: string; // EXPLAIN -- here for templates, but not in IUserAccountGeneral
	public readonly fbCreationTimeISO: null | Date = null;
	public readonly fbLastRefreshTimeISO: null | Date = null;
	public readonly fbLastSignInTimeISO: null | Date = null;
	public readonly sortableLastLogin: Date = new Date(1900, 0);
	public readonly sortableName: string;
	public readonly statusIsActive: boolean;
	public readonly salutatedName: string;

	//TODO: This field is here to a patch Ray's new model interface w/ the original model.
	//		We'll revise the references, since we no longer build on top of the Firebase object.
	public readonly emailVerified: boolean;

	constructor(
		//IMPORTANT: Keep these members intact, for interoperability with IUserAccountGeneral!
		public readonly id: string,
		public readonly avatarUrl: string,
		public readonly name: string,
		public readonly email: string,
		public readonly phoneNumber: string,
		public readonly address: string,
		public readonly country: string,
		public readonly state: string,
		public readonly city: string,
		public readonly zipCode: string,
		public readonly company: string,
		public readonly isVerified: boolean,
		public readonly status: string, // should be private, but it's used too heavily by Minimal
		// public readonly role: string,
		public readonly role: Roles,

		//IMPORTANT: Firebase User fields
		// public readonly emailVerified: boolean,      // UNUSED
		// public readonly isAnonymous: boolean,        // UNUSED
		// public readonly metadata: UserMetadata,      // UNUSED
		// public readonly providerData: UserInfo[],    // UNUSED
		// public readonly refreshToken: string,        // UNUSED
		// public readonly tenantId: string | null,     // UNUSED

		//IMPORTANT: Firebase UserInfo fields (which Firebase User extends)
		// public readonly displayName: string | null,  // duplicates an IUserAccountGeneral field
		// public readonly email: string | null,        // duplicates an IUserAccountGeneral field
		// public readonly phoneNumber: string | null,  // duplicates an IUserAccountGeneral field
		public readonly photoURL: string | null,
		// public readonly providerId: string,          // UNUSED
		// public readonly uid: string                  // UNUSED

		//NOTE: These fields may be modified as needed.
		public readonly externalAccountID: string,
		public readonly nameFirst: string,
		public readonly nameLast: string,
		public readonly salutation: string,
		private _stripe_customer_id: string,
		private readonly _fbCreationTime: string,
		private readonly _fbLastRefreshTime: string,
		private readonly _fbLastSignInTime: string
	) {
		this.displayName = nameFirst + ' ' + nameLast;
		this.name = this.displayName;
		this.sortableName = this.name.toLowerCase();
		this.salutatedName = (salutation.trim() !== '' ? salutation + ' ' : '') + this.name;
		this.statusIsActive = this.status === UserStatus.Active;

		if (this._fbCreationTime) {
			this.fbCreationTimeISO = new Date(Date.parse(this._fbCreationTime));
		}
		if (this._fbLastRefreshTime) {
			this.fbLastRefreshTimeISO = new Date(Date.parse(this._fbLastRefreshTime));
		}
		if (this._fbLastSignInTime) {
			this.fbLastSignInTimeISO = new Date(Date.parse(this._fbLastSignInTime));
		}

		// one-time set our effective last login, for the table sort
		if (this.fbLastRefreshTimeISO !== null) {
			this.sortableLastLogin = this.fbLastRefreshTimeISO;
		}
		else if (this.fbLastSignInTimeISO !== null) {
			this.sortableLastLogin = this.fbLastSignInTimeISO;
		}
		// else use the default (pre-ship) date

		this.emailVerified = isVerified;

		const incomingRoleFound = roleNames.find((roleName: Roles) => {
			if (roleName === this.role) {
				return true;
			}
			return false;
		});

		if (!incomingRoleFound) {
 			// console.warn('user role not supported: ' + this.role);
			// default to user
			this.role = Roles.User;
		}

		// set the country to "US" if it looks like it's supposed to be US (e.g. "U.S.A.")
		if (!this.country) {
			if (this.phoneNumber) {
				// no country but there is a phone; use default
				this.country = DefaultCountry.code;
			}
		}
		else if (checkCodeIsLikeUS(this.country)) {
			this.country = DefaultCountry.code;
		}
		else if (!countryConfig.find((c) => c.code === this.country)) {
			// user country unknown; default to US
			this.country = DefaultCountry.code;
		}
	}

	ExportAsFirestoreJSON() {
		return {
			// Minimal fields
			id: this.id,
			avatarUrl: this.avatarUrl,
			name: this.name,
			email: this.email,
			phoneNumber: this.phoneNumber,
			address: this.address,
			country: this.country,
			state: this.state,
			city: this.city,
			zipCode: this.zipCode,
			company: this.company,
			isVerified: this.isVerified,
			status: this.status,
			role: this.role,
			photoURL: this.photoURL,
			salutation: this.salutation,
			stripe_customer_id: this._stripe_customer_id,
			externalAccountID: this.externalAccountID,
			displayName: this.displayName,
			nameFirst: this.nameFirst,
			nameLast: this.nameLast,
			sortableName: this.sortableName,
			salutatedName: this.salutatedName
		};
	}

	CheckFirebaseUserAttached() {
		return this._firebaseUser !== null;
	}

	GetFirebaseUser() {
		if (!this.CheckFirebaseUserAttached()) {
			throw new Error('getting null firebase user');
		}

		return this._firebaseUser;
	}

	SetFirebaseUser(user: User) {
		if (this.CheckFirebaseUserAttached()) {
			//NOTE: This rule can be removed if needed. It's here because we
			//      don't anticipate ever replacing a user (no recycling models).
			throw new Error('replacing existing firebase user');
		}

		this._firebaseUser = user;
	}

	GetStripeCustomerId() {
		return this._stripe_customer_id;
	}

	SetStripeCustomerId(stripe_customer_id: string) {
		this._stripe_customer_id = stripe_customer_id;
	}
};


export function InstantiateUserFromJSON(jsonRecord: any) {
	const newModel = new UserAccountGeneral(
		jsonRecord.id,
		jsonRecord.avatarUrl,
		jsonRecord.name,
		jsonRecord.email,
		jsonRecord.phoneNumber,
		jsonRecord.address,
		jsonRecord.country,
		jsonRecord.state,
		jsonRecord.city,
		jsonRecord.zipCode,
		jsonRecord.company,
		jsonRecord.isVerified,
		jsonRecord.status,
		jsonRecord.role,
		jsonRecord.photoURL,
		jsonRecord.externalAccountID,
		jsonRecord.nameFirst,
		jsonRecord.nameLast,
		jsonRecord.salutation,
		jsonRecord.stripe_customer_id,
		jsonRecord.fbCreationTime,
		jsonRecord.fbLastRefreshTime,
		jsonRecord.fbLastSignInTime);

	return newModel;
};

export function mapUserDefaultValues(currentUser: UserAccountGeneral) {
    const defaultCountry = currentUser.country ? countryConfig
        .find(country => country.name.toLowerCase() === currentUser.country.toLowerCase() || country.code.toLowerCase() === currentUser.country.toLowerCase())
        ?.code || '' : ''
    const defaultStateDivisions = currentUser.country ? countryConfig
        .find(country => country.name.toLowerCase() === currentUser.country.toLowerCase() || country.code.toLowerCase() === currentUser.country.toLowerCase())
        ?.divisions : undefined;
    const defaultState = !defaultStateDivisions ? '' : defaultStateDivisions.find((division) => division.code.toLowerCase() === currentUser.state.toLowerCase() || division.name.toLowerCase() === currentUser.state.toLowerCase())?.name || '';
    return {
        nameFirst: currentUser.nameFirst,
        nameLast: currentUser.nameLast,
        email: currentUser.email,
        targetEmail: currentUser.email,
        isVerified: currentUser.isVerified,
        role: currentUser.role,
        phoneNumber: currentUser?.phoneNumber || '',
        address: currentUser?.address || '',
        country: defaultCountry,
        state: defaultState,
        city: currentUser?.city || '',
        zipCode: currentUser?.zipCode || '',
        avatarUrl: currentUser?.avatarUrl || null,
        status: currentUser?.status || '',
        salutation: currentUser?.salutation || '',

        // here for one RHF checkbox, which requires a bool
        statusIsActive: currentUser?.statusIsActive || false
    }
};
