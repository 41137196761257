import { Navigate, useLocation } from 'react-router-dom';
// import { PATH_ADMIN, PATH_HOME } from '../routes/paths';
import { useAuthContext } from './useAuthContext';
import { Roles, routeRoleAsAdmin } from '../utils/mrr/userConstants';
import { PATH_ADMIN, PATH_APP } from '../routes/paths';
// import { Roles, routeRoleAsAdmin } from '../assets/data';

// ----------------------------------------------------------------------
type AuthGuardProps = {
  children: React.ReactNode;
};

// We use "admin" as our path for protected pages, but there is no technical
// relation between these. It could be any string.
const adminPathName = Roles.Admin;

const verboseLogging = false;

export default function AdminGuard({ children }: AuthGuardProps) {
  const { user } = useAuthContext();
  const { pathname } = useLocation();

  verboseLogging && console.log('ADMIN GUARD', user, user ? user.role : 'n/a', pathname);;

  if (user && user.role) {
    if (routeRoleAsAdmin(user.role)) {
      if (!pathname.includes(adminPathName)) {
        return <Navigate to={PATH_ADMIN.user.list} replace />
      }
      // else fall through
    }
    else if (pathname.includes(adminPathName)) {
      // this role cannot visit admin routes
      return <Navigate to={PATH_APP.app} replace />
    }
  }

  return <> {children} </>;
}
