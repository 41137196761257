import { Button, IconButton, Stack, StackProps, Typography, TypographyProps } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import Iconify from "../../components/iconify";
import MenuPopover from "../../components/menu-popover";
import { ListingGeneral } from "../../models/ListingGeneral";
import { PATH_APP } from "../../routes/paths";
import { fBathrooms } from "../../utils/mrr/fBathrooms";
import { fBedrooms } from "../../utils/mrr/fBedrooms";
import createGeoLink from "../../utils/mrr/url/createGeoLink";

const componentStyles = {
    property: { variant: 'h6' } as TypographyProps,
    checkInOut: {
        typography: 'body2',
        color: 'text.primary',
        direction: "row",
        alignItems: "center",
    } as StackProps,
    checkInOutContainer: {
        divider: <>&emsp;</>,
        direction: "row",
        flexWrap: 'wrap'
    } as StackProps,
    labelContainer: {
        typography: 'caption',
        color: 'text.primary',
        direction: "row",
        flexWrap: "wrap",
        columnGap: 1.5,
        rowGap: 1
    } as StackProps,
    pricingContainer: {
        display: 'none'
    } as StackProps,
    footerContainer: {
        direction: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center'
    } as StackProps
};
interface ListingCardContentProps {
    listing: ListingGeneral;
    disableNav?: boolean;
}

export function ListingCardContent({
    listing,
    disableNav = false
}: ListingCardContentProps) {
    const {
        name_detailed: property,
        bedrooms,
        bathrooms,
        unit_view,
    } = listing;
    const [open, setOpen] = useState<HTMLElement | null>(null);

    const handleCloseMenu = () => {
        setOpen(null);
    }
    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        event.stopPropagation()
        setOpen(event.currentTarget);
    }

    const navigate = useNavigate();
    const navigateToReservation = () => {
        if (disableNav) {
            return;
        }
        navigate(PATH_APP.createListing(listing.id));
    };
    return (
        <>
            <Stack
                id={`${property}_listing_card_content`}
                flexGrow={1} // will make this container fill most of the empty space
                onClick={navigateToReservation}
                sx={{
                    ...(!disableNav && {
                        cursor: 'pointer',
                        position: 'relative',
                    })
                }}
            >
                <Stack id={`${property}_reservation_card_content_header`}
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'>
                    <Typography
                        variant='h6'
                    >
                        {property}
                    </Typography>
                    <IconButton
                        onClick={handleOpenMenu}>
                        <Iconify
                            width={18}
                            icon="eva:more-horizontal-fill" />
                    </IconButton>
                </Stack>
                <Stack
                    spacing={1} // spacing between view label and beds ・ baths
                >
                    <Stack
                        {...componentStyles.checkInOutContainer}
                    >
                        <Stack
                            {...componentStyles.labelContainer}
                        >
                            <Stack
                                direction="row"
                                alignItems="center">
                                <Iconify icon="mdi:bedroom-outline" width={18} sx={{ mr: 0.5 }} />
                                {fBedrooms(bedrooms)}
                            </Stack>

                            <Stack
                                direction="row"
                                alignItems="center">
                                <Iconify icon="mdi:bathroom" width={18} sx={{ mr: 0.5 }} />
                                {fBathrooms(bathrooms)}
                            </Stack>

                            <Stack
                                direction="row"
                                alignItems="center">
                                <Iconify icon="uil:scenery" width={18} sx={{ mr: 0.5 }} />
                                {unit_view}
                            </Stack>

                        </Stack>
                    </Stack>

                </Stack>
            </Stack>
            <MenuPopover
                open={open}
                onClose={handleCloseMenu}
                arrow="top-right"
            >
                <Stack spacing={1}>
                    <Button
                        fullWidth
                        sx={{ display: 'flex', justifyContent: 'flex-start' }}
                        component={NavLink}
                        to={listing.booking_url}
                        rel="noopener noreferrer"
                        target="_blank"
                        color='primary'
                    >
                        <Iconify mr={1} icon="mdi:calendar-plus" />
                        Book this Property
                    </Button>
                    {typeof listing.latitude === 'number' &&
                        typeof listing.longitude === 'number' &&
                        <ViewInMapsButton lat={listing.latitude} long={listing.longitude} />}
                </Stack>
            </MenuPopover>
        </>
    );
}

function ViewInMapsButton({ lat, long }: { lat: string | number, long: string | number }) {
    const link = createGeoLink(lat, long);
    return <Button
        fullWidth
        sx={{ display: 'flex', justifyContent: 'flex-start' }}
        component='a'
        href={link}
        rel="noopener noreferrer"
        target="_blank"
        color='warning'
    >
        <Iconify mr={1} icon="mdi:location-radius-outline" />
        View in Maps
    </Button>
}