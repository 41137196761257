// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

export const PATH_ROOT = '/';
const ROOTS_ADMIN = '/admin';
const ROOTS_AUTH = '/user';
const ROOTS_PAYMENT = '/payment';

export const OOB_ACTION = 'action';
export const OOB_EMAIL_VERIFIED = 'email-verified';
export const OOB_FORGOT_PASSWORD = 'forgot-password';
export const OOB_SET_PASSWORD = 'set-password';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginSuccess: path(ROOTS_AUTH, '/login/success'),
  register: path(ROOTS_AUTH, '/register'),
  verify: path(ROOTS_AUTH, '/verify'),
  notVerified: path(ROOTS_AUTH, '/not-verified'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
  action: path(ROOTS_AUTH, `/${OOB_ACTION}`),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  error: '/error',
  maintenance: '/maintenance',
  // pricing: '/pricing',
  // payment: '/payment',
  // about: '/about-us',
  // contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  logout: '/logout'
};

export const PATH_APP = {
  app: PATH_ROOT,
  createPolicy: (reservationName: string) => `/reservation/travel-insurance/${reservationName}`,
  createReservation: (reservationName: string) => `/reservation/${reservationName}`,
  createListing: (listingId: string) => `/property/${listingId}`,
  listings: '/properties',
  reservations: '/reservations',
  guests: '/guests',
  payment: {
    root: ROOTS_PAYMENT,
    confirm: path(ROOTS_PAYMENT, '/confirm'),
  },
  createGuest: (guestId: string) => `/guest/${guestId}`,
  createInvoice: (reservationName: string) => `/reservation/invoice/${reservationName}`,
  createInvoiceEdit: (reservationName: string) => `/reservation/invoice/${reservationName}/edit`,
}

export const PATH_USER = {
  root: '/user',
  account: '/user/account',
}

/*Unwanted Minimal store code
export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
};

export const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/';

export const PATH_MINIMAL_ON_STORE = 'https://mui.com/store/items/minimal-dashboard/';

export const PATH_FREE_VERSION = 'https://mui.com/store/items/minimal-dashboard-free/';

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/rWMDOkMZYw2VpTdNuBBCvN/%5BPreview%5D-Minimal-Web.26.11.22?node-id=0%3A1&t=ya2mDFiuhTXXLLF1-1';
*/

export const PATH_ADMIN = {
  root: ROOTS_ADMIN,
  blank: path(ROOTS_ADMIN, '/blank'),
  general: {
    app: path(ROOTS_ADMIN, PATH_ROOT),
  },
  user: {
    root: path(ROOTS_ADMIN, '/user'),
    profile: path(ROOTS_ADMIN, '/user/profile'),
    // cards: path(ROOTS_ADMIN, '/user/cards'),
    list: path(ROOTS_ADMIN, '/user/list'),
    emulator: path(ROOTS_ADMIN, '/user/emulator'),
    new: path(ROOTS_ADMIN, '/user/new'),
    // edit: (name: string) => path(ROOTS_ADMIN, `/user/${name}/edit`),
    edit: (email: string) => path(ROOTS_ADMIN, `/user/${email}/edit`),
    account: path(ROOTS_ADMIN, '/user/account'),
  },
  property: {
    root: path(ROOTS_ADMIN, '/property'),
    // profile: path(ROOTS_ADMIN, '/property/profile'),
    // list: path(ROOTS_ADMIN, '/property/list'),
    // new: path(ROOTS_ADMIN, '/property/new'),
    edit: (id: string) => path(ROOTS_ADMIN, `/property/${id}/edit`)
  },
  notVerified: path(ROOTS_ADMIN, '/not-verified')
};
