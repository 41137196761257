import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import LoadingScreen from '../components/loading-screen';
import OobSignInPage from '../pages/auth/OobSignInPage';
import { useAuthContext } from './useAuthContext';
import MRRNotVerifiedPage from '../pages/auth/MRRNotVerifiedPage';
import { PATH_AUTH } from '../routes/paths';
import { ReservationInfoContextProvider } from '../ReservationInfoContext';
import { ListingInfoContextProvider } from '../ListingInfoContext';
import { hasSessionAdmin } from '../utils/mrr/emulator/signInAsOtherUser';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: React.ReactNode;
};

const verboseLogging = false;

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, isInitialized, user, adminSessionChanging } = useAuthContext();

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  verboseLogging && console.warn('AUTH GUARD',
    '\nisAuthenticated:', isAuthenticated,
    '\nrequestedLocation:', requestedLocation,
    '\npathname:', pathname,
    '\nuser:', user,
    '\nadminSessionChanging:', adminSessionChanging,
    '\nhasSessionAdmin:', hasSessionAdmin());

  if (!isInitialized || adminSessionChanging) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      verboseLogging && console.warn('AUTH GUARD SAVE; state pathname:', pathname);
      setRequestedLocation(pathname);
    }
    return <OobSignInPage />;
  }

  if (user && !user.emailVerified) {
    // we have a valid user that has not yet validated their email by means of emailed link.
    if (pathname !== PATH_AUTH.notVerified) {
      return <MRRNotVerifiedPage />;
    }
  }

  if (requestedLocation && pathname !== requestedLocation) {
    verboseLogging && console.warn('AUTH GUARD NAVIGATE; state var:', requestedLocation);
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  verboseLogging && console.warn('AUTH GUARD CONTINUE');

  return (
    //NOTE: Only the innermost child blocks with a loading screen. This lets the preloaders run in parallel.
    <ReservationInfoContextProvider>
      <ListingInfoContextProvider>
        {children}
      </ListingInfoContextProvider>
    </ReservationInfoContextProvider>
  );
}
