import {
    Button,
    List,
    ListItemButton,
    Stack,
    Typography
} from "@mui/material";
import { isPast, setHours } from "date-fns";
import { mapIconToSubType } from "../../../../components/containers/CardPickerInput";
import { CenteredRow } from "../../../../components/containers/CenteredRow";
import { SplitRow } from "../../../../components/containers/SplitRow";
import Iconify from "../../../../components/iconify";
import {
    InstantiateROIFromJSON,
    ROIGeneral
} from "../../../../models/ROIGeneral";
import { fCurrency } from "../../../../utils/formatNumber";

export function SelectServiceStep({
    rois, selectService, onBack
}: {
    rois: ROIGeneral[];
    selectService: (service: ROIGeneral) => void;
    onBack?: VoidFunction;
}) {

    return (
        <Stack spacing={2}>
            <List>
                <Stack spacing={2}>
                    {rois.map(
                        (roi, index) => (
                            <AddServiceRow
                                roi={roi}
                                selectService={selectService}
                                selected={false}
                                key={'Add_' + index} />
                        ))}
                </Stack>
            </List>
            {onBack &&
                <Stack direction='row'>
                    <Button onClick={onBack}>Back</Button>
                </Stack>
            }
        </Stack>
    );
}
function AddServiceRow(
    {
        roi, selectService, selected
    }: {
        roi: ROIGeneral;
        selectService: (service: ROIGeneral) => void;
        selected: boolean;
    }
) {

    const handleSelectROI = (undatedRoi: ROIGeneral) => {
        const clone = InstantiateROIFromJSON({ ...undatedRoi, description: '' });
        selectService(clone);
    };

    const serviceNotAvailable = isPast(setHours(new Date(roi.service_window_end), 16))

    return (
        <ListItemButton
            selected={selected}
            disabled={serviceNotAvailable}
            onClick={() => { handleSelectROI(roi) }}
            sx={{
                minHeight: 65,
                p: 0
            }}>
            <SplitRow spacing={1}>
                <Stack
                    flexGrow={1}>

                    <SplitRow>
                        <CenteredRow>
                            <Iconify icon={mapIconToSubType(roi.sub_type)} mr={1} />
                            <Typography variant="h6">
                                {roi.sub_type}
                            </Typography>
                        </CenteredRow>
                        <Typography variant="subtitle1">
                            {fCurrency(roi.amount, true)}
                        </Typography>
                    </SplitRow>


                    <Typography variant="body2">
                        {roi.description}
                    </Typography>
                </Stack>
                <Stack>
                    <Iconify icon='ep:arrow-right-bold' ml={0.5} />
                </Stack>
            </SplitRow>
        </ListItemButton>
    );
}
