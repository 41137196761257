import {
    Stack
} from "@mui/material";
import {
    parseISO
} from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import {
    useMemo,
    useState
} from "react";
import { brandConfig } from "src/config";
import { createAnalyticsPaymentItem, itemCategory, paymentEvent, useAnalyticsContext } from "../../../analytics";
import { FullscreenModal } from "../../../components/fullscreen-modal/FullscreenModal";
import { SectionLoading } from "../../../components/loading-screen/SectionLoading";
import StripePayment from "../../../components/stripe/StripePayment";
import {
    ROIGeneral
} from "../../../models/ROIGeneral";
import { ReservationGeneral } from "../../../models/ReservationGeneral";
import {
    useGetAdditionalServicesQuery,
    useGetHybridReservationsQuery,
    usePostReservationOrderItemsMutation
} from "../../../redux/rtkQuery/apiSlice";
import { PATH_APP } from "../../../routes/paths";
import { cloud_getAdditionalReservationOrderItems, cloud_postReservationOrderItems } from "../../../utils/mrr/cloudFunctions";
import { StandardRTKQError } from "../../error/StandardRTKQError";
import { SelectDateStep } from "./AddService/SelectDateStep";
import { SelectHybridChild } from "./AddService/SelectHybridChild";
import { SelectServiceStep } from "./AddService/SelectService";
import { AddServiceNotes } from "./AddServiceNotes";

export enum AddServiceFormStep {
    SelectReservation,
    SelectService,
    SelectDate,
    AddNotesAndConfirm,
    StripePaymentStep
};

export function AddService({
    open, handleClose, reservation
}: {
    open: boolean;
    handleClose: VoidFunction;
    reservation: ReservationGeneral;
}) {
    const [formStep, setFormStep] = useState<AddServiceFormStep>(reservation?.is_hybrid_parent ? AddServiceFormStep.SelectReservation : AddServiceFormStep.SelectService);
    const [selectedHybridReservation, setSelectedHybridReservation] = useState<ReservationGeneral | null>(null);
    const [selectedService, setSelectedService] = useState<ROIGeneral | null>(null);
    const [notes, setNotes] = useState('');
    const { tagPurchaseEvent } = useAnalyticsContext()

    const {
        data: nonHybridROIs,
        isFetching: isFetchingNonHybridROIS,
        isSuccess: isSuccessNonHybridROIS,
        isError: isErrorNonHybridROIS,
        error: errorNonHybridROIS
    } = useGetAdditionalServicesQuery({
        reservationName: reservation.name
    }, {
        skip: reservation.is_hybrid_parent
    });

    const {
        data: hybridReservations,
        isFetching: hybridIsFetching,
        isSuccess: hybridIsSuccess,
    } = useGetHybridReservationsQuery(
        { reservationName: reservation?.name },
        { skip: !reservation || !reservation.is_hybrid_parent }
    )
    const [
        sendPostAdditionalService,
        {
            isLoading: isLoadingPostROI,
            isError: postIsError,
            error: postROIError,
            data: postROIData,
            isSuccess: postROISuccess
        }
    ] = usePostReservationOrderItemsMutation();

    // Step 0: If hybrid parent
    const handleSelectHybridReservation = (selectedReservation: ReservationGeneral) => {
        setSelectedHybridReservation(selectedReservation);
        setFormStep(AddServiceFormStep.SelectService);
        tagPurchaseEvent(paymentEvent.VIEW_ITEM, [createAnalyticsPaymentItem(selectedReservation, 0, itemCategory.ADD_SERVICE)], 0, undefined, 'USD')
    }
    const removeSelectedHybridAddService = () => {
        setSelectedHybridReservation(null)
        setSelectedService(null)
        setFormStep(AddServiceFormStep.SelectReservation);
    }
    const {
        data: hybridROIs,
        isFetching: isFetchingHybridROIS,
        isSuccess: isSuccessHybridROIS,
        isError: isErrorHybridROIS,
        error: errorHybridROIS
    } = useGetAdditionalServicesQuery({
        reservationName: (selectedHybridReservation?.name || '')
    }, {
        skip: !selectedHybridReservation
    });

    // Step 1: Choose a service from the menu.
    const updateSelectedService = (roi: ROIGeneral) => {
        setSelectedService(roi)
    }
    const handleSelectService = (roi: ROIGeneral) => {
        setSelectedService(roi)
        tagPurchaseEvent(paymentEvent.VIEW_ITEM, [createAnalyticsPaymentItem(roi, 0, itemCategory.ADD_SERVICE)], roi.amount, undefined, 'USD')
        setFormStep(AddServiceFormStep.SelectDate);
    }
    const removeSelectedService = () => {
        setSelectedService(null)
        setFormStep(AddServiceFormStep.SelectService);
    }

    // Step 2: Choose a date and time for the service.
    const handleDateSelected = () => {
        setFormStep(AddServiceFormStep.AddNotesAndConfirm);
    };
    const handleReturnToDateSelection = () => {
        setFormStep(AddServiceFormStep.SelectDate)
    }

    // Step 3: Add custom notes.
    const handleContinueToPayment = async (roi: ROIGeneral) => {
        if (!roi) {
            return
        }

        const sfPayload = {
            reservationName: roi.reservation_name, // for cache tagging, only
            type: roi.type,
            sub_type: roi.sub_type,
            amount: roi.amount,
            description: roi.description,
            start_time: roi.start_time_ISO instanceof Date
                ? zonedTimeToUtc(roi.start_time_ISO, brandConfig.timezone).toJSON()
                : parseISO(roi.service_window_start).toJSON()
        };
        await sendPostAdditionalService(sfPayload);

        setFormStep(AddServiceFormStep.StripePaymentStep);
    };
    const handleReturnToNotes = () => {
        setFormStep(AddServiceFormStep.AddNotesAndConfirm);
    }
    // ----------

    const redirectToReservation = useMemo(() => {
        if (!postROISuccess || !postROIData) {
            return window.location.href
        }
        const endpoint = PATH_APP.createReservation(postROIData.reservation_name)
        return window.location.origin + endpoint
    }, [postROIData, postROISuccess])

    if (!reservation) {
        return null;
    }
    // ---------- Error handler
    if (isErrorNonHybridROIS) {
        return (
            <FullscreenModal showDialog={open} onClose={handleClose} titleText='Add Service'>
                <StandardRTKQError
                    mutationCall={false}
                    endpoint={cloud_getAdditionalReservationOrderItems}
                    error={errorNonHybridROIS} />
            </FullscreenModal>
        )
    }
    if (isErrorHybridROIS) {
        return (
            <FullscreenModal showDialog={open} onClose={handleClose} titleText='Add Service'>
                <StandardRTKQError
                    mutationCall={false}
                    endpoint={cloud_getAdditionalReservationOrderItems}
                    error={errorHybridROIS} />
            </FullscreenModal>
        )
    }
    if (postIsError) {
        return (
            <FullscreenModal showDialog={open} onClose={handleClose} titleText='Add Service'>
                <Stack spacing={2}>
                    <StandardRTKQError
                        mutationCall
                        endpoint={cloud_postReservationOrderItems}
                        error={postROIError} />
                    {selectedService &&
                        <AddServiceNotes
                            notes={notes}
                            setNotes={setNotes}
                            isLoading={isLoadingPostROI}
                            handleConfirm={handleContinueToPayment}
                            handleNavBack={handleReturnToDateSelection}
                            roi={selectedService}
                            selectService={updateSelectedService} />
                    }
                </Stack>
            </FullscreenModal>
        )
    }

    const modalTitle = createModalTitle(isFetchingNonHybridROIS || isFetchingHybridROIS, formStep, selectedService?.sub_type)

    const modalLoading = isFetchingNonHybridROIS || hybridIsFetching || isFetchingNonHybridROIS || isFetchingHybridROIS

    const ROIsToDisplay = isSuccessHybridROIS ? hybridROIs : isSuccessNonHybridROIS ? nonHybridROIs : []

    return (
        <FullscreenModal showDialog={open} onClose={handleClose} titleText={modalTitle}>
            <Stack spacing={2} pt={1}>
                {modalLoading && !postIsError
                    && <SectionLoading size={6} />
                }
                {!modalLoading && !postIsError &&
                    <>
                        {/* Step 0: Choose a hybrid child from the menu. */}
                        {formStep === AddServiceFormStep.SelectReservation && hybridIsSuccess &&
                            <SelectHybridChild
                                reservations={hybridReservations}
                                selectReservation={handleSelectHybridReservation}
                            />
                        }
                        {/* Step 1: Choose a service from the menu. */}
                        {formStep === AddServiceFormStep.SelectService &&
                            <SelectServiceStep
                                rois={ROIsToDisplay}
                                selectService={handleSelectService}
                                onBack={reservation.is_hybrid_parent ? removeSelectedHybridAddService : undefined}
                            />
                        }
                        {/* Step 2: Choose a date and time for the service. */}
                        {formStep === AddServiceFormStep.SelectDate && selectedService &&
                            <SelectDateStep
                                isLoading={isLoadingPostROI}
                                handleDoneChoosingDate={handleDateSelected}
                                handleNavBack={removeSelectedService}
                                reservation={reservation}
                                roi={selectedService}
                                selectService={updateSelectedService}
                            />
                        }
                        {/* Step 3: Add custom notes. */}
                        {formStep === AddServiceFormStep.AddNotesAndConfirm && selectedService &&
                            <AddServiceNotes
                                notes={notes}
                                setNotes={setNotes}
                                isLoading={isLoadingPostROI}
                                handleConfirm={handleContinueToPayment}
                                handleNavBack={handleReturnToDateSelection}
                                roi={selectedService}
                                selectService={updateSelectedService}
                            />
                        }
                        {/* Step 4: Payments */}
                        {formStep === AddServiceFormStep.StripePaymentStep && postROISuccess && postROIData &&
                            <StripePayment
                                amount={postROIData.total_amount}
                                cancelButtonText="Back"
                                description={`${postROIData.reservation_name}: ${postROIData.sub_type}`}
                                onClose={handleReturnToNotes}
                                reservationOrderItem={postROIData}
                                return_url={redirectToReservation}
                                category={itemCategory.ADD_SERVICE}
                            />
                        }
                    </>
                }
            </Stack>
        </FullscreenModal>
    );
}

function createModalTitle(
    loading: boolean,
    formStep: AddServiceFormStep,
    subtype?: string
) {
    return loading
        ? 'Loading Services'
        : formStep === AddServiceFormStep.SelectReservation
            ? 'Select Property'
            : formStep === AddServiceFormStep.SelectService
                ? `Select Service`
                : formStep === AddServiceFormStep.SelectDate
                    ? `Select Date And Time`
                    : formStep === AddServiceFormStep.AddNotesAndConfirm
                        ? `Confirm ${subtype}`
                        : formStep === AddServiceFormStep.StripePaymentStep
                            ? ``
                            : 'Add Service';
}
