import { Box, Card, Stack, styled } from '@mui/material';
import { PropsWithChildren } from 'react';
import { StyledCardContent } from '../../components/containers/StyledCardContent';
// ----------------------------------------------------------------------


export default function LoginLayout({ children }: PropsWithChildren) {
    return (
        <>
            <StyledBackground />
            <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                width={1}
                height={1}
                flexGrow={1}>
                <Card raised sx={{ width: { sm: 400, xs: 350 } }}>
                    <StyledCardContent>
                        <Stack className='login-content' sx={{ width: 1 }}>
                            {children}
                        </Stack>
                    </StyledCardContent>
                </Card>
            </Box>
        </>

    );
}

//----------------
const StyledBackground = styled('div')(({ theme }) => ({
    backgroundImage: 'url(/assets/background/bg_img1_33pct.jpg)',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: -1
}))

